// extracted by mini-css-extract-plugin
export var container = "ProfileLoginInformationPage-module--container--9EJsO";
export var content = "ProfileLoginInformationPage-module--content--1K-hk";
export var title = "ProfileLoginInformationPage-module--title--3MZsV";
export var goBackIcon = "ProfileLoginInformationPage-module--goBackIcon--2iICa";
export var box = "ProfileLoginInformationPage-module--box--Wd_Az";
export var infoItem = "ProfileLoginInformationPage-module--infoItem--3inq-";
export var itemTitle = "ProfileLoginInformationPage-module--itemTitle--1dKBd";
export var itemDescription = "ProfileLoginInformationPage-module--itemDescription--3-Gu9";
export var link = "ProfileLoginInformationPage-module--link--1zTC9";
export var footerContainer = "ProfileLoginInformationPage-module--footerContainer--1KswE";