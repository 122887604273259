import React, { useEffect } from "react";
import { Link } from "gatsby";
import { connect } from "react-redux";
import { trackingGA } from '@merchx-v2/helpers'
import { navigate } from 'utils/navigate'
import { Footer, Header, SEO } from "components";
import goBackIcon from "assets/goBack.svg";

import {
  actionCreators as actionHomeCreators,
  selectMenu,
} from "state/HomePage/reducer";
import { selectLoginState } from "state/LoginPage/reducer";

import * as styles from "./ProfileLoginInformationPage.module.scss";

const ProfileLoginInformationPage = ({
  loginState,
  menu,

  resetMenuFilters,
  setMenuCollection,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    trackingGA()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCollectionClicked = (collection) => {
    setMenuCollection(collection);
  };

  const handleReset = () => {
    resetMenuFilters();
  };

  if (typeof localStorage === 'undefined' || typeof sessionStorage === 'undefined') {
    return null
  }

  const refreshToken =
    localStorage.getItem("refreshToken") ||
    sessionStorage.getItem("refreshToken");

  if (!refreshToken) {
    return null;
  }

  return (
    <div className={styles.container}>
      <SEO pathname='/profile/login-information' />
      <Header
        menu={menu}
        onCollectionClicked={handleCollectionClicked}
        onResetCollection={handleReset}
        isNeedDescription={true}
      />
      <div className={styles.content}>
        <div className={styles.title}>
          <img
            className={styles.goBackIcon}
            src={goBackIcon}
            alt="<"
            onClick={() => navigate('/profile')}
          />
          Login Information
        </div>
        <div className={styles.box}>
          <div className={styles.infoItem}>
            <p className={styles.itemTitle}>Email address</p>
            <p className={styles.itemDescription}>{loginState?.email || ""}</p>
          </div>
          <div className={styles.infoItem}>
            <p className={styles.itemTitle}>Password</p>
            <p className={styles.itemDescription}>********</p>
          </div>
          <Link to="/profile/change-password" className={styles.link}>
            Change Password
          </Link>
        </div>
      </div>
      <div className={styles.footerContainer}>
        <Footer handleReset={handleReset} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loginState: selectLoginState(state),
  menu: selectMenu(state),
});

const mapDispatchToProps = {
  resetMenuFilters: actionHomeCreators.resetMenuFilters,
  setMenuCollection: actionHomeCreators.setMenuCollection,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileLoginInformationPage);
